.menu-wrap {
    width: 100%;
    /* height: calc(100vh - 112px); */
    /* min-height: 100vh; */
    position: relative;
    padding: 80px 80px 40px 80px;
}

.menu-bg {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #002F55;
}

.menu-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.title {
    width: 100%;
    text-align: center;
    position: relative;
    padding: 0px 40px 20px 40px;
}
.title h1 {
    width: 100%;
    color: #ffffff;
    text-align: center;
}
.title h2 {
    width: 100%;
    color: #ffffff;
    text-align: center;
}

@media only screen and (max-width: 1200px) {

    .menu-wrap {
        padding: 95px 40px 80px 40px;
    }

}
@media only screen and (min-width: 960px) {
    .menu-wrap {
        padding: 95px 40px 120px 40px;
        min-height: 640px;
    }
}

/* mobile */
@media only screen and (max-width: 960px) {

    .menu-wrap {
        padding: 95px 20px 20px 20px;
    }

    .menu-links {
        width: 100%;
        flex-wrap: wrap;
    }

}

/* mobile */
@media only screen and (max-width: 720px) {


    .menu-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        padding: 20px;
    }
    .title {
        padding: 20px 0px 40px 0px;
    }

}