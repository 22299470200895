.question {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 80px;
}
.question p {
    line-height: 1.2;
}
.color-bg {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 40px;
    left: 0;
    background-color: #002F55;
    background-image: url('../../../assets/images/spheres-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}

.question-wrap {
    width: 100%;
    position: relative;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}
.result {
    width: 100%;
    /* max-width: 700px; */
    /* height: calc(100vh - 112px); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 112px 0px 2%;
    background-color: #002F55;
}
.result-wrap {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px 20px;
}
.result .trophy {
    width: 120px;
    height: 120px;
    margin: 0px 0px 30px 0px;
}
.result .trophy img {
    width: 100%;
    display: block;
}
.result p {
    font-size: 20px;
    color: #ffffff;
}
.result p.register {
    font-size: 15px;
}
.result p.register a {
    color: #ffffff;
}
.result p.register a:link {
    color: #ffffff;
}
.result p.register a:visited {
    color: #ffffff;
}
.result p.register a:hover {
    color: #ffffff;
}
.result p.register a:active {
    color: #ffffff;
}

.result h1 {
    font-size: 48px;
    color: #ffffff;
}
.result form {
    width: 100%;
    padding: 0;
    margin: 30px 0px;
}

.options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
}
.reversed {
    flex-direction: row-reverse;
}
.option-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.option-parent {
    position: relative;
    width: 48%;
}
.option {
    position: relative;
    width: 100%;
}
.option img {
    display: block;
}


.option-click {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.option-click:hover {
    background-color: rgba(255, 255, 255, .3);
}
.option-selected {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-shadow:inset 0px 0px 0px 10px #943AF4;
    -moz-box-shadow:inset 0px 0px 0px 10px #943AF4;
    box-shadow:inset 0px 0px 0px 10px #943AF4;
    /* pointer-events: none; */
}
.option-disabled {
    pointer-events: none;
}



.optNum {
    width: 58px;
    height: 58px;
    background-color: #943AF4;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
    z-index: 10;
}

.b {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 25 25' style='enable-background:new 0 0 25 25;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Benable-background:new ;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg class='st0'%3E%3Cpath class='st1' d='M12.4,0.3c1.6,0,2.9,0.2,4,0.5s2,0.7,2.7,1.3s1.2,1.2,1.6,2s0.5,1.7,0.5,2.7c0,0.6-0.1,1.1-0.3,1.7 c-0.2,0.5-0.4,1-0.8,1.5s-0.8,0.9-1.4,1.3c-0.6,0.4-1.2,0.7-2,0.9c3.5,0.7,5.2,2.6,5.2,5.5c0,1-0.2,2-0.6,2.9s-1,1.6-1.7,2.2 s-1.7,1.1-2.7,1.5c-1.1,0.4-2.3,0.5-3.7,0.5h-9V0.3H12.4z M8.3,3.4V11h4c1.7,0,3-0.3,3.8-1c0.8-0.7,1.3-1.6,1.3-2.8 c0-1.3-0.4-2.3-1.2-2.9s-2-0.9-3.7-0.9C12.5,3.4,8.3,3.4,8.3,3.4z M13.2,21.6c0.9,0,1.7-0.1,2.3-0.3c0.6-0.2,1.1-0.5,1.5-0.9 s0.7-0.8,0.8-1.3s0.3-1,0.3-1.6c0-1.2-0.4-2.1-1.2-2.7c-0.8-0.7-2-1-3.7-1H8.3v7.8C8.3,21.6,13.2,21.6,13.2,21.6z'/%3E%3C/g%3E%3C/svg%3E");
}
.a {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 25 25' style='enable-background:new 0 0 25 25;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Benable-background:new ;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg class='st0'%3E%3Cpath class='st1' d='M24.2,24.7h-3.1c-0.4,0-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.4-0.5-0.7l-2-5.4H7.2l-2,5.4c-0.1,0.2-0.2,0.4-0.5,0.6 c-0.2,0.2-0.5,0.3-0.9,0.3h-3l9.7-24.5h4L24.2,24.7z M16.7,15.6l-3.4-8.9C13.1,5.9,12.8,5,12.5,4c-0.1,0.5-0.3,1-0.4,1.5 c-0.1,0.4-0.3,0.8-0.4,1.2l-3.4,8.9C8.3,15.6,16.7,15.6,16.7,15.6z'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.footnote {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
}
.footnote-wrap {
    width: 100%;
    position: relative;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
    margin: 10px 0px 0px;
}
.footnote-holder {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footnote-parent {
    position: relative;
    width: 48%;
}
.footnote-parent p {
    font-size: 18px;
    text-align: left;
    color: #002F55;
}

.globalFootnote {
    width: 100%;
    position: relative;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4%;
    margin: 10px auto 0px;
}
.globalFootnote .globalFootnoteCopy {
    font-size: 14px;
    color: #000000;
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif !important;
    text-align: center;
    margin-bottom: 15px;
}

.footnote-0 .footenoteBodyCopy {
    display: none;
}

.footnote-1 .footenoteCNSCopy, .footnote-2 .footenoteCNSCopy, .footnote-3 .footenoteCNSCopy, .footnote-4 .footenoteCNSCopy {
    display: none;
}

.submit-wrap {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    scroll-margin-bottom: 140px;
}
.submit-parent {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
    margin: 10px 0px 40px;
}

.next-wrap {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
}
.next-parent {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
    margin: 10px 0px 40px;
}




/* .answer {

} */

.answer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    display: none;
}
.answer-wrap {
    width: 100%;
    position: relative;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
}


.answer-content {
    width: 100%;
    padding: 30px 0px;
}
.summary-content {
    width: 100%;
   
}
.summary-copy {
    width: 100%;
    padding: 0px;
}
.answer-copy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fdbk-img {
    width: 80px;
    height: 80px;
}
.fdbk-img img {
    width: 100%;
}
.summary-copy img {
    margin-top: 30px;
}
.answer-wrap h1 {
    font-size: 60px;
    margin-bottom: 20px;
    margin-top: 5px;
}
.green-txt { color: #00B92B !important; }
.red-txt { color: #B90000; }

.answer-wrap p, .summary-wrap p {
    font-size: 24px;
    color: #002F55;
}

.img-footnote {
    width: 100%;
    padding: 20px;
}
.img-footnote p {
    font-size: 11px;
    line-height: 1.2;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;
}

.mobile-footnotes {
    margin-top: 10px;
    margin-bottom: 20px;
    display: none;
}
.mobile-footnotes p {
    font-size: 18px;
    text-align: left;
    color: #ffffff;
}



.summary {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, .1);
    display: none;
    margin: 0px;
}
.summary-wrap {
    width: 100%;
    position: relative;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
}

.show-answer {
    display: flex;
    margin: 20px 0px;
}
.show-summary {
    display: flex;
    margin: 20px 0px;
}

.submit, .next {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    padding: 15px 80px;
    background-color: #943AF4;
    margin-top: 10px;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.submit:hover, .next:hover {
    background-color: #CA9DFA;
}
.submit-dis, .next-dis {
    display: none;
}

/* Refs */

.refs {
    width: 100%;
    position: relative;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    justify-content: center;
}
.refs-sum {
    width: 100%;
    position: relative;
    margin: 20px 0px;
    padding: 0;
    display: flex;
    justify-content: center;
}
.refs-wrap {
    width: 100%;
    max-width: 1100px;
    padding: 0 4%;
}
.refsum {
    width: 100%;
    display: flex;
    align-items: center;
}
.refsum img {
   margin-right: 5px;
}
.refs p, .refs-sum p {
    color: #002F55;
    font-size: 11px;
    text-align: left;
    padding: 0;
    margin: 0;
}
/* .refs-wrap {
    width: 100%;
    position: relative;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    padding: 0 4%;
} */
.form-iframe {
    width: 100%;
    min-height: 320px;
    height: auto;
}

@media only screen and (max-width: 1200px) {

    .question {
        padding-top: 95px;
    }

    .answer-wrap {
        padding: 0 8%;
    }

    .answer-wrap p {
        font-size: 20px;
    }

    .fdbk-img {
        width: 50px;
        height: 50px;
    }

    .answer-wrap h1 {
        font-size: 42px;
    }

    .summary-wrap {
        padding: 0 8%;
    }

    .summary-wrap p {
        font-size: 20px;
    }

    .result {
        padding: 105px 0px 2%;
    }

    .result .trophy {
        width: 80px;
        height: 80px;
        margin: 0px 0px 20px 0px;
    }

    .result p {
        font-size: 18px;
        margin-bottom: 0;
    }



}



@media only screen and (max-width: 720px) {

    .option-parent {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .options {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .option {
        width: 80% ;
    }

    .mobile-footnotes {
        display: block;
        width: 80%;
    }

    .color-bg {
        bottom: 0;
    }

    .footnote {
        display: none;
    }

    .answer-content {
        width: 100%;
        padding: 20px 0px 0px;
    }

}

@media only screen and (max-width: 560px) {

    
    .result h1 {
        font-size: 38px;
    }

}