.ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    padding: 20px !important;
    transition: opacity 200ms ease-in-out;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  .close-btn {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    border-radius: 36px;
    border: 1px solid #943AF4;
    background-color: #943AF4;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
  }
  .close-btn:hover {
    border-radius: 36px;
    background-color: #BF89F8;
  }
  .close-btn svg, .close-btn-transparent svg {
    width: 12px !important;
  }
  .ReactModal__Content {
    margin: 0 !important;
  }
  .ReactModal__Content {
    position: relative;
    max-width: 1024px;
    padding: 0px !important;
  }
  .scroll-class {
    max-height: 1024px;
    overflow: unset !important; 
    border-top-right-radius: 4px !important;
  
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .modal-header {
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 800px;
    height: 72px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 10;
    border-bottom: 1px solid #005EB8;
  }
  .modal-header-transparent {
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    z-index: 100;
    padding: 20px 30px 0px 30px;
    background-color: #ffffff;
    text-align: center;
  }
  .modal-content {
   /* max-height: 800px; */
   margin-top: -30px;
   padding: 20px 30px 30px 30px;
  }
  .modal-content-phase {
   max-height: 800px;
   margin-top: 0px;
   padding: 20px 30px 30px 30px;
  }
  /* .modal-content h2 {
   font-family: Arial, Helvetica, sans-serif;
   font-size: 16px;
   font-weight: bold;
   line-height: 1.4;
   color: #033056;
   text-transform: uppercase;
   margin-bottom: 5px;
  }
  .modal-content h3 {
   font-family: Arial, Helvetica, sans-serif;
   font-size: 21px;
   font-weight: bold;
   line-height: 1.1;
   color: #0460A9;
  } */
  #react-modal {
    width: 100%;
    max-width: 1024px;
    max-height: calc(100vh - 120px);
    overflow-y: auto !important;
    padding: 20px;
  }
  .modal-content-scroll-class {
    padding: 20px 80px;
    margin-bottom: 10px;
  }
  .modal-content-scroll-statue-class {
    padding: 40px 40px;
    margin-bottom: 10px;
  }

  
  
  
  @media (max-width: 960px) {
  

    
  }
  
  @media (max-width: 460px) {
  

    
  }